@import "../../scss/global";

.brand-image {
  width: 50%;

  @media (min-width: 768px) {
    width: 150px;
  }

  @media (min-width: 992px) {
    width: 200px;
  }

  &-greyscale {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
}
