$default-font: "Open Sans", sans-serif;
$heading-font: "Encode Sans Expanded", sans-serif;

$blue: #244b8a;
$purple: #870793;
$brown: #934107;
$green: #0c8a00;
$white: #ffffff;
$black: #000000;
$grey: #b4b4b4;
