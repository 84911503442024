@import url("https://fonts.googleapis.com/css?family=Encode+Sans+Expanded:700|Open+Sans:400,700&display=swap");

@import "./variables";
@import "./mixins";

.ui.header {
  text-align: left;
  margin-bottom: 30px;
  font-family: $heading-font;
}

* {
  font-family: $default-font;
}

#root {
  overflow-x: hidden;
}
