@import "../../scss/global";

.image-header {
  width: 100%;
  height: 550px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  &-home,
  &-not-found {
    background-image: url("../../../public/img/astro.jpg");
  }

  &-portfolio {
    background-image: url("../../../public/img/desk.jpg");
    background-position: 0% 50%;
  }

  text-align: center;

  .full-name {
    color: $white;
    position: relative;
    top: 20%;
    font-size: 48px;
    font-family: $heading-font;
  }

  .statement {
    color: $white;
    font-size: 20px;
    font-weight: bold;
    line-height: 45px;
    margin: 15px 15px 0 15px;
    position: relative;
    top: 25%;

    .fa-quote-left,
    .fa-quote-right {
      margin-bottom: 10px;
    }
  }
}