@import "../../scss/global";

.footer {
  height: 100px;
  background-color: $black;
  width: 100%;
  color: $white;
  font-size: 14px;

  .copyright {
    position: relative;
    top: 50px;
    left: 5px;

    @include desktop {
      top: 42px;
    }
  }
}
