.blurb {
  margin-top: 25px;
  font-size: 20px;
  line-height: 27px;
  text-align: left;

  .line {
    margin-top: 20px;

    &:first-of-type {
      font-weight: bold;
    }
  }
}
