@import '../../scss/global';

@-webkit-keyframes fill {
    0% { background-color: transparent; color: $white; }
    100% { background-color: $white; color: $black; }
}

@keyframes fill {
    0% { background-color: transparent; color: $white; }
    100% { background-color: $white; color: $black; }
}

@-webkit-keyframes unfill {
    0% { background-color: $white; color: $black; }
    100% { background-color: transparent; color: $white; }
}

@keyframes unfill {
    0% { background-color: $white; color: $black; }
    100% { background-color: transparent; color: $white; }
}

.ui.menu.navigation-bar {
    background-color: transparent;
    -webkit-animation-name: unfill;
            animation-name: unfill;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;

    a {
        text-decoration: none;
        color: $white;
        cursor: pointer;
    }

    &-fill {
        background-color: $white;
        -webkit-animation-name: fill;
                animation-name: fill;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        
        a {
            color: $black;
        }
    }
}