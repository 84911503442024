@import "../../scss/global";

.project {
  margin-top: 50px;
  text-align: center;

  .brand-image {
    width: 300px;
  }

  &-title {
    margin-top: 15px;
    font-size: 18px;
    font-family: $heading-font;

    a {
      font-family: inherit;
      text-decoration: none;
      color: $black;
    }
  }

  &-description {
    margin: 15px 0 50px 0;
    text-align: left;
    font-size: 16px;
  }
}
