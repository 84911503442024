@import "../../scss/global";

.contact {
  color: $white;
  top: 50%;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  font-size: 30px;
  
  &-small {
    font-size: 14px;
    top: 34px;

    @include desktop {
      float: right;
      position: relative;
      right: 20px;
    }
  }

  .telephone {
    font-weight: bold;

    @include tablet {
      display: none;
    }

    &-small {
      display: inline;
    }

    &-number {
      display: none;

      @include tablet {
        display: inline;
      }
    }
  }

  a {
    color: $white;
    text-decoration: none;
    cursor: pointer;
  }
}
