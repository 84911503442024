@import '../../scss//global';

.ui.container.introduction {
  margin-top: 35px;
  text-align: center;

  .pic {
    width: 350px;
    border-radius: 50%;
    margin-top: 38px;
  }
}

.skills-list {
  padding: 50px 0;
  margin: 60px 0;
  background-color: $blue;

  .ui.header.skills-header {
    color: $white;
  }
}